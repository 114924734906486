import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Action, Category } from "constants/events";
import { useEventTracker } from "hooks/useEventTracker";
import { setUserMenuPreference } from "redux/action-creators/user";
import { getRecreationalAlternateTerm } from "redux/selectors/complianceRules";
import { getUserPrefersMedical } from "redux/selectors/user";
import { AppThunkDispatch } from "redux/store";
import { getLongTitle, getShortTitle, MenuType } from "utils/menuTypeUtils";

import Button from "components/botanic/Button";
import { InformationalModal } from "components/botanic/Modal/InformationalModal";
import Radio from "components/botanic/Radio";
import SolidArrowIcon from "components/Icons/arrows/SolidArrowIcon";
import RadioGroup from "components/RadioGroup";

const getModalOptions = (recreationalTermOverride?: string) =>
  Object.freeze([
    {
      label: recreationalTermOverride
        ? recreationalTermOverride
        : getLongTitle(MenuType.Rec),
      value: MenuType.Rec,
    },
    { label: `${getLongTitle(MenuType.Med)}`, value: MenuType.Med },
  ]);

const MenuTypeButton: React.FC<{
  dispensaryId: number;
  onMenuTypeChange?: (value: MenuType.Med | MenuType.Rec) => void;
}> = ({ dispensaryId, onMenuTypeChange }) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const recreationalTermOverride = useSelector(getRecreationalAlternateTerm);
  const { publishEvent } = useEventTracker();

  const userPrefersMedical = useSelector(getUserPrefersMedical);
  const initialMenuType = userPrefersMedical ? MenuType.Med : MenuType.Rec;

  const buttonRef = useRef<HTMLButtonElement>(null);

  const [open, setOpen] = useState(false);
  const [selectedMenuType, setSelectedMenuType] = useState<
    MenuType.Med | MenuType.Rec
  >();

  useEffect(() => {
    if (userPrefersMedical !== undefined && !selectedMenuType) {
      setSelectedMenuType(initialMenuType);
    }
  }, [userPrefersMedical]);

  const shortTitle =
    recreationalTermOverride && initialMenuType === MenuType.Rec
      ? recreationalTermOverride
      : getShortTitle(initialMenuType);

  const onDismissed = () => {
    if (selectedMenuType && initialMenuType !== selectedMenuType) {
      publishEvent({
        action: Action.click,
        category: Category.dispensaryMenu,
        dispensaryId,
        label: `menu switcher ${selectedMenuType}`,
      });
      dispatch(setUserMenuPreference(selectedMenuType));
      onMenuTypeChange?.(selectedMenuType);
    }

    setOpen(false);
  };

  return (
    <>
      <button
        className="button button--secondary w-[initial] py-sm pl-md pr-sm block whitespace-nowrap"
        ref={buttonRef}
        onClick={() => {
          setOpen(true);
        }}
        data-testid="menu-type-button"
        aria-label={`Menu: ${shortTitle}`}
      >
        <span>Menu:</span>
        <span className="ml-xs">{shortTitle}</span>
        <SolidArrowIcon
          className="inline-block ml-xs md:mr-0 mr-lg"
          height="24px"
          width="24px"
        />
      </button>
      {open && (
        <InformationalModal
          onDismissed={onDismissed}
          returnFocusRef={buttonRef}
          title="Choose your menu"
        >
          <RadioGroup name="menuType">
            {getModalOptions(recreationalTermOverride).map(
              ({ value, label }, i) => (
                <Radio
                  key={`menu-type-radio-${i}`}
                  id={`menu-type-radio-${value}`}
                  checked={selectedMenuType === value}
                  ariaLabel={label}
                  className="my-sm"
                  onChange={() => setSelectedMenuType(value)}
                  data-testid={`menu-type-button-option-${value}`}
                >
                  <>
                    <span>{label}</span>
                    {value === MenuType.Med &&
                      selectedMenuType === MenuType.Med && (
                        <p className="text-xs text-error">
                          You may need a valid medical ID to place an order from
                          this menu.
                        </p>
                      )}
                  </>
                </Radio>
              ),
            )}
          </RadioGroup>
          <div className="border-t border-light-grey absolute md:relative bottom-0 p-lg md:pb-none w-full md:w-auto md:flex flex-row-reverse bg-white -mx-lg">
            <Button
              onClick={onDismissed}
              className="mx-auto w-full md:mb-none"
              data-testid="menu-type-button-confirm"
            >
              confirm
            </Button>
          </div>
        </InformationalModal>
      )}
    </>
  );
};

export default MenuTypeButton;
